import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import styled from "styled-components";

const FaqStyles = styled.div`
  li {
    button {
      padding: 1em 1em 0 1.5em;
    }
    background-color: ${(props) =>
      props.isOpen ? "var(--color-very-light-gray);" : "transparent;"};
    .answer {
      overflow: hidden;
      .answer-wrapper {
        padding: 1.5em;
      }
    }
  }
`;

const Faq = ({ i, expanded, setExpanded, faq }) => {
  const isOpen = i === expanded;

  return (
    <FaqStyles isOpen={isOpen}>
      <motion.li>
        <motion.button
          whileHover={{
            x: 10,
          }}
          onClick={() => setExpanded(isOpen ? false : i)}
        >
          {faq.question}
        </motion.button>
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, height: "auto" },
                collapsed: { opacity: 0, height: 0 },
              }}
              transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
              className="answer"
            >
              <div className="answer-wrapper">{faq.answer}</div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.li>
    </FaqStyles>
  );
};

export default Faq;
