import React, { useState } from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import Seo from "../components/seo";
import FaqsSection from "../components/FaqsSection";
import TopTitleBar from "../components/TopTitleBar";
import { useStaticQuery, graphql } from "gatsby";
import MaxWidthSection from "../components/Layout/MaxWidthSection";
import { GatsbyImage } from "gatsby-plugin-image";

const FaqsStyles = styled.main`
  grid-column: 1 / span 14;
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, 5.2rem)) 1fr;
  gap: 0 2rem;
  margin: 0;

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 1 / span 8;
    grid-template-columns: 2rem repeat(6, 1fr) 2rem;
    gap: 0 1rem;
    margin: 0;
  }

  @media ${(props) => props.theme.breakpoints.s} {
    grid-template-columns: 1rem repeat(6, 1fr) 1rem;
    gap: 0 1rem;
    margin: 0;
  }

  .faq-section {
    padding: 4em 0;
  }

  .questions {
    grid-column: 1 / span 6;
    ul {
      padding-top: 2em;
      li {
        list-style: none;
        margin-bottom: 1em;

        button {
          text-decoration: none;
          color: var(--color-accent);
          transition: color 300ms;
          background: none;
          border: none;
          text-align: left;
          cursor: pointer;
          &:hover {
            color: var(--color-accent-light);
            font-weight: 600;
          }
        }
      }
    }
  }
  .faq-image {
    grid-column-end: span 6;
  }
`;

const Faqs = () => {
  const data = useStaticQuery(graphql`
    query FAQsQuery {
      allSanityFaqs {
        nodes {
          question
          answer
          _id
        }
      }
      file(name: { eq: "faq" }) {
        childImageSharp {
          gatsbyImageData(
            width: 700
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);

  const faqs = data.allSanityFaqs.nodes;
  const image = data.file.childImageSharp.gatsbyImageData;

  const [expanded, setExpanded] = useState(null);

  return (
    <Layout>
      <Seo title="FAQ's" />
      <TopTitleBar title="Frequently Asked Questions" />
      <FaqsStyles>
        <MaxWidthSection className="faq-section">
          <div className="questions">
            <ul>
              {faqs.map((faq, i) => (
                <FaqsSection
                  key={faq._id}
                  i={i}
                  faq={faq}
                  expanded={expanded}
                  setExpanded={setExpanded}
                />
              ))}
            </ul>
          </div>
          <div className="faq-image">
            <GatsbyImage image={image} alt="Girl Questioning" />
          </div>
        </MaxWidthSection>
      </FaqsStyles>
    </Layout>
  );
};

export default Faqs;
